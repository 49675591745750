.modal {
  position: fixed;
  top: calc(50% - 200px);
  left: calc(50% - 200px);
  padding: 25px 50px;
  z-index: 5;
  height: 400px;
  width: 400px;
  box-shadow: $box-shadow-primary;
  background-color: #FFFFFF;
  transition: 0.7s;
  @include flex-column-justify-between-align-center;

  &-inputs-list {
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  &-container {
    transition: 0.7s;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.5);
    z-index: 4;
  }
  &-buttons {
    @include flex-row-align-center;
    button {
      margin-right: 8px;
    }
  }
}