.white {
  background-color: #80DEEA;
}
.yellow {
  background: #42A5F5;
}
.black-yellow {
  background: #FFCA28;
}
.light-green {
  background: #9CCC65;
}
.green {
  background: #43A047;
}
.red {
  background: #EF5350;
}