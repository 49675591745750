@mixin flex-column-justify-between-align-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-column-justify-center-align-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flex-row-justify-between-align-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-row-justify-center-align-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@mixin flex-row-align-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@mixin flex-column-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}