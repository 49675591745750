.application {
  &-header {
    @include font-header;
  }
  &-buttons{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 20px 50px;
    .button-common {
      margin: 0;
    }
  }
  &-modal.modal {
    padding: 20px;
    top: calc(50% - 300px);
    left: calc(50% - 350px);
    height: 600px;
    width: 700px;
    @include flex-column-justify-between-align-center;
  }
  &-pack {
    @include font-text;
  }
  &-status {
    display: inline-block;
    border: 1px solid black;
    padding: 4px 6px;
    position: relative;
    &-list {
      position: absolute;
      top: 0;
      left: 0;
      &-item {
        padding: 5px;
        border: 1px solid gray;
      }
    }
    @include font-text;
  }
  &-field {
    width: 70%;
    @include flex-row-align-center;
    p {
      margin-right: 20px;
    }
  }
  &-text {
    @include font-text
  }
  &-input.common-input {
    border: 2px solid $additional-color;
  }
  &-date-picker {
    padding: 4px 11px;
  }
  &-text {
    color: rgba(17, 17, 17, 0.48);
    margin: 9px 27px;
    font-family: Exo, sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.02em;
    border: none;
  }
  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}
.ant-table-tbody > tr.ant-table-row-level-1:hover > td {
  background: unset!important;
}
.link {
  color: #42A5F5;
  cursor: pointer;
}