.loader {
  margin: 25px auto;
  display: block;
  height: 25px;
  width: 25px;
  border: 4px $primary-color solid;
  border-top: 4px $additional-color solid;
  border-radius: 50%;
  -webkit-animation: spin4 1s infinite linear;
  animation: spin4 1s infinite linear;
}

@-webkit-keyframes spin4 {
  from {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes spin4 {
  from {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}